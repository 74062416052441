import dayjs from 'dayjs';
import styled from 'styled-components';

import WareHouseCountdown from 'Pages/WarehouseManager/WareHouseCountdown';
import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import Products from 'Shared/Warehouse/Products';

import Box from './Box/Box';
import Colors from './Colors/Colors';
import Equipment from './Equipment/Equipment';
import Hooks from './Hooks/Hooks';
import RemoveButton from './RemoveButton';

const Order = ({ order }) => {
   return (
      <tr>
         <td>
            <ZamowieniaLink displayId={order.zamowieniaId} zamowieniaId={order.zamowieniaId} />
         </td>
         <td>{order.companyName}</td>
         <td>
            <Rows>
               <span>
                  {order.checkedInAt} dnia {order.addedAt}
               </span>
               {order.startedPaintingAt && (
                  <WareHouseCountdown date={dayjs(order.startedPaintingAt, 'HH:mm DD-MM-YYYY')} />
               )}
            </Rows>
         </td>
         <td>
            <Products products={order.products} />
         </td>
         <td>
            <Colors order={order} />
         </td>
         <td>
            <Hooks order={order} />
         </td>
         <td>
            <Box order={order} />
         </td>
         <td>
            <Equipment order={order} />
         </td>
         <td>
            <RemoveButton order={order} />
         </td>
      </tr>
   );
};

const Rows = styled.div`
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
`;

export default Order;
