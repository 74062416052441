export const TYPE_CONSTRUCTION_FOR_GOODS = 'CONSTRUCTION_FOR_GOODS';
export const TYPE_CONSTRUCTION_FOR_POLES_SMALL = 'CONSTRUCTION_FOR_POLES_SMALL';
export const TYPE_CONSTRUCTION_FOR_POLES_BIG = 'CONSTRUCTION_FOR_POLES_BIG';
export const TYPE_DEPOSIT_FREE = 'DEPOSIT_FREE';
export const TYPE_BOX = 'BOX';
export const TYPE_EQ_POLE = 'EQ_POLE';
export const TYPE_GATE_SELF_SUPPORTING = 'GATE_SELF_SUPPORTING';
export const TYPE_GATE_SELF_SUPPORTING_LONG = 'GATE_SELF_SUPPORTING_MULTI_ELEMENT';
export const TYPE_ACCESSORIES = 'ACCESSORIES';
export const TYPE_CONCRETE = 'CONCRETE';
export const TYPE_CARPORT = 'CARPORT';
// It's regarding galvanized poles (actually Galfan shell/coat)
export const TYPE_GALFAN = 'GALFAN';
export const TYPE_GALVANIZED = 'GALVANIZED';
// Towar luzem
export const TYPE_LOOSE_GOODS = 'LOOSE_GOODS';
// Panele z placu
export const TYPE_PANELS_FROM_SQUARE = 'PANELS_FROM_SQUARE';
// Euro Konsport
// To store palettes with poles one on top of the other
export const TYPE_EURO_KONSPORT = 'EURO_KONSPORT';
// Ramiak + paleta słipów
export const TYPE_CONSTRUCTION_AND_POLES_PALETTE = 'CONSTRUCTION_AND_POLES_PALETTE';

export const paletteTypes = {
   [TYPE_CONSTRUCTION_FOR_GOODS]: 'Ramiak na towar',
   [TYPE_CONSTRUCTION_FOR_POLES_SMALL]: 'Ramiak na słupki wąskie',
   [TYPE_CONSTRUCTION_FOR_POLES_BIG]: 'Ramiak na słupki szerokie',
   [TYPE_DEPOSIT_FREE]: 'Paleta niekaucyjna',
   [TYPE_BOX]: 'Karton',
   [TYPE_EQ_POLE]: 'Słupek EQ',
   [TYPE_GATE_SELF_SUPPORTING]: 'Brama samonośna',
   [TYPE_GATE_SELF_SUPPORTING_LONG]: 'Brama samonośna skręcana',
   [TYPE_ACCESSORIES]: 'Akcesoria',
   [TYPE_CONCRETE]: 'Beton',
   [TYPE_CARPORT]: 'Carport',
   // It's regarding galvanized poles (actually Galfan shell/coat)
   [TYPE_GALFAN]: 'Galfan',
   [TYPE_GALVANIZED]: 'Ocynk',
   // Towar luzem
   [TYPE_LOOSE_GOODS]: 'Towar luzem',
   // Panele z placu
   [TYPE_PANELS_FROM_SQUARE]: 'Panele z placu',
   // Euro Konsport
   [TYPE_EURO_KONSPORT]: 'Euro Konsport',
   // Ramiak + paleta słupów
   [TYPE_CONSTRUCTION_AND_POLES_PALETTE]: 'Ramiak + paleta słupów',
};

export const attribute = (key, attributes) => {
   const attr = attributes.find((a) => a.key === key);
   return attr ? attr.value : '';
};
