import axios from 'axios';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import styled from 'styled-components';

import { Context } from '../../Context';
import { OPTIONS } from '../Shared/Options';

const Equipment = ({ order }) => {
   const { refetch } = useContext(Context);
   const [disabledSelect, setDisabledSelect] = useState(true);

   const equipmentInfoObj =
      order.attributes.find((attr) => attr.key === 'info-about-equipment') ?? null;
   const equipmentInfoValue = equipmentInfoObj ? JSON.parse(equipmentInfoObj.value) : '';

   const formik = useFormik({
      initialValues: {
         equipment: '',
      },
      onSubmit: (values) => {
         axios
            .put(`/paint-shop/orders/${order.id}/info-about-equipment`, {
               info: values.equipment,
            })
            .then(() => {
               refetch();
            });
      },
   });

   const equipmentOptions = OPTIONS.map((o) => (
      <option key={o.value} value={o.value}>
         {o.label}
      </option>
   ));

   return (
      <Container onSubmit={formik.handleSubmit}>
         <label htmlFor="equipment">
            Wyposażenie
            <select
               id="equipment"
               className="form-control"
               name="equipment"
               onChange={(e) => {
                  formik.handleChange(e);
                  formik.submitForm();
                  setDisabledSelect(true);
               }}
               onBlur={formik.handleBlur}
               value={formik.values.equipment || equipmentInfoValue}
               disabled={disabledSelect}
            >
               <option value="" />
               {equipmentOptions}
            </select>
         </label>
         <EditBtn
            className="btn btn-success"
            type="button"
            onClick={() => setDisabledSelect(false)}
         >
            Edytuj
         </EditBtn>
      </Container>
   );
};

const EditBtn = styled.button`
   margin-top: 1rem;
`;

const Container = styled.form`
   display: flex;
   flex-direction: column;
   position: relative;
`;

export default Equipment;
