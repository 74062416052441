import { useContext } from 'react';

import CancelButton from 'Shared/Warehouse/CancelButton';
import { packingPlaces } from 'Shared/Warehouse/OrderPartsGroup';
import { TYPE_CONSTRUCTION_AND_POLES_PALETTE } from 'Shared/Warehouse/Palette';
import { CompanyName } from 'Shared/Warehouse/Warehouse';

import { Context } from './Context';
import FinalPalette from './FinalPalette';
import PlanPalettesAgainButton from './PlanPalettesAgainButton';
import ToBeSentButton from './ToBeSentButton';

const Group = ({ group }) => {
   const { refetch } = useContext(Context);
   const palettes = group.finalPalettes.map((p, i) => {
      const next = group.finalPalettes[i + 1];
      const nextPaletteIsConstructionAndPoles =
         next && next.type === TYPE_CONSTRUCTION_AND_POLES_PALETTE;
      return (
         <FinalPalette
            key={p.id}
            finalPalette={p}
            nextPaletteIsConstructionAndPoles={nextPaletteIsConstructionAndPoles}
         />
      );
   });

   return (
      <tr>
         <td>{group.publicId}</td>
         <td>
            <CompanyName
               orderPartsGroup={group}
               createdByExternalClient={group.createdByExternalClient}
            />
         </td>
         <td>{packingPlaces[group.packingPlace]}</td>
         <td>{group.packedAt}</td>
         <td>{palettes}</td>
         <td>
            <CancelButton group={group} refetch={refetch} />
            <PlanPalettesAgainButton group={group} />
            <ToBeSentButton group={group} />
         </td>
      </tr>
   );
};

export default Group;
