import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { OPTIONS } from 'Pages/PaintShop/Orders/Shared/Options';
import {
   ORDER_ATTRIBUTE_COLOR,
   ORDER_ATTRIBUTE_SKIP_PAINTING,
   paintShopPaintOptions,
} from 'Shared/Warehouse/OrderPart';

const now = dayjs().format('DD-MM-YYYY--HH-mm');

const headers = [
   { label: 'Data', key: 'date' },
   { label: 'Id', key: 'zamowieniaId' },
   { label: 'Email', key: 'email' },
   { label: 'Kolor', key: 'color' },
   { label: 'Pudełko', key: 'box' },
   { label: 'Osprzęt', key: 'equipment' },
];

const findOrderColors = (attributes) => {
   const colors = attributes.find((a) => a.key === ORDER_ATTRIBUTE_COLOR);
   if (!colors) {
      return [];
   }
   if (typeof colors.value === 'string') {
      return JSON.parse(colors.value);
   }
   return [];
};

const findTranslation = (attribute) => {
   if (attribute) {
      return OPTIONS.find((o) => o.value === JSON.parse(attribute))?.label || attribute;
   }
   return '';
};

const ExportButton = () => {
   const orders = useSelector((state) => state.warehouse.orders);
   const startedPaintingAtRange = useSelector((s) => s.warehouse.filters.startedPaintingAtRange);
   const rangeStartDate = startedPaintingAtRange
      ? dayjs(startedPaintingAtRange.from).format('DD-MM-YYYY')
      : '';
   const rangeEndDate = startedPaintingAtRange
      ? dayjs(startedPaintingAtRange.to).format('DD-MM-YYYY')
      : '';
   const data = orders.map((o) => {
      const addedAtDate = dayjs(o.addedAt, 'HH:mm DD-MM-YYYY').format('DD.MM.YYYY HH:mm');
      const date = startedPaintingAtRange ? `${rangeStartDate} - ${rangeEndDate}` : addedAtDate;
      const orderColors = findOrderColors(o.attributes);
      const orderColorsString = orderColors.join(', ');
      const box = o.attributes.find((attr) => attr.key === 'info-about-box')?.value;
      const equipment = o.attributes.find((attr) => attr.key === 'info-about-equipment')?.value;

      const skipPainting = o.attributes
         .find((a) => a.key === ORDER_ATTRIBUTE_SKIP_PAINTING)
         ?.value.map((v) => {
            // ESLINT_WARN_TO_BE_FIXED
            // eslint-disable-next-line no-shadow
            return paintShopPaintOptions.find((order) => order.id === v).label;
         })
         .join(', ');

      return {
         date,
         zamowieniaId: o.zamowieniaId,
         email: o.createdBy.email,
         color: orderColorsString,
         skipPainting,
         box: findTranslation(box),
         equipment: findTranslation(equipment),
      };
   });

   return (
      <Container>
         <CSVLinkStyled
            className="btn btn-success btn-icon"
            data={data}
            headers={headers}
            filename={`lakiernia-${now}.csv`}
            style={{ marginBottom: '.5rem' }}
            target="_blank"
            separator=";"
         >
            <FontAwesomeIcon icon={faFileAlt} />
            Pobierz jako CSV
         </CSVLinkStyled>
      </Container>
   );
};

const Container = styled.div`
   margin-top: 1rem;
`;

const CSVLinkStyled = styled(CSVLink)`
   width: 260px;
`;

export default ExportButton;
